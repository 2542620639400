<!--
 * @Author: nice
 * @Date: 2021-11-13 14:07:39
 * @LastEditTime: 2021-12-04 10:43:49
 * @LastEditors: nice
 * @Description: 欠费详情
-->
<template>
  <section class="arrears-detail">
    <NavBar title="欠费详情" background="blue" />
    <div class="main-height-x">
      <p class="price">{{ obj.amountMoney }}元</p>
      <van-cell title="水费" class="self-van-cell" style="margin-top:1px">
        <template #default>
          <span>{{ obj.waterCharge }}元</span>
        </template>
      </van-cell>
      <p class="other-price">
        <span>起度</span>
        <span>{{obj.waterChargeAccountOrderVO ? obj.waterChargeAccountOrderVO.startReadNumber : '-'}}</span>
      </p>
      <p class="other-price">
        <span>止度</span>
        <span>{{obj.waterChargeAccountOrderVO ? obj.waterChargeAccountOrderVO.endReadNumber : '-'}}</span>
      </p>
      <p class="other-price">
        <span>用水量</span>
        <span>{{obj.waterChargeAccountOrderVO ? obj.waterChargeAccountOrderVO.useWaterNumberStr : '-'}}</span>
      </p>
      <van-cell title="附加费" class="self-van-cell" style="margin-top:1px">
        <template #default>
          <span>{{ obj.surChargeCharge }}元</span>
        </template>
      </van-cell>
      <p class="other-price" v-for="(item, index) in obj.surChargeList" :key="index + item.chargeItemName">
        <span>{{ item.chargeItemName }}</span>
        <span>{{ item.totalPrice }}元</span>
      </p>
      <van-cell title="其他费" class="self-van-cell" style="margin-top:1px">
        <template #default>
          <span>{{ obj.otherChargeCharge }}元</span>
        </template>
      </van-cell>
      <p class="other-price" v-for="(item, index) in obj.otherChargeList" :key="index + item.chargeItemName">
        <span>{{ item.chargeItemName }}</span>
        <span>{{ item.totalPrice }}元</span>
      </p>

      <van-cell title="开账日期" style="margin-top:1px">
        <template #default>
          <span>{{ $tTime(obj.createTime) }}</span>
        </template>
      </van-cell>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    let { item } = this.$route.query;
    item = JSON.parse(item);
    return {
      obj: item
    };
  },
  created() {
    this.hideshare();
    console.log(this.obj)
  },
  methods: {
    onBridgeReady(){
      WeixinJSBridge.call('hideOptionMenu');
    },
    hideshare () {
      if (typeof WeixinJSBridge == "undefined"){
        if( document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        }else if (document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      }else{
        this.onBridgeReady();
      }
    },
  }
};
</script>

<style lang="less">
.arrears-detail {
  .price {
    background-color: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #F2995F;
  }
  .self-van-cell {
    &::after {
      border: none;
    }
  }
  .other-price {
    display: flex;
    justify-content: space-between;
    // margin-top: 5px;
    width: 100%;
    background: #fff;
    font-size: 12px;
    color: #969799;
    padding: 0 4.167vw;
    box-sizing: border-box;
    padding-bottom: 5px;
  }
}
</style>
